import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import path from 'path'
// require('dotenv').config({path: __dirname + '../.env'});

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0,
    term: '',
    monumentData: { body: {}, errorStatus: '' },
    featuredData: { body: {}, errorStatus: '' },
    figureData: { body: {}, errorStatus: '' },
    curriculumData: { body: {}, errorStatus: '' },
    relatedData: { header: {}, body: {}, errorStatus: '' },
    titleData: { body: {}, errorStatus: '' },
    loadingStatus: [],
    pdfLoaded: { page: 0, status: false, pageHeight: '', errorStatus: '' },
    suggestedTerm: '',
    visited: []
  },
  getters: {
    term: state => {
      return state.term
    },
    suggestedTerm: state => {
      return state.suggestedTerm
    },
    titleData: state => {
      return state.titleData
    },
    monumentData: state => {
      return state.monumentData
    },
    featuredData: state => {
      return state.featuredData
    },
    figureData: state => {
      return state.figureData
    },
    curriculumData: state => {
      return state.curriculumData
    },
    relatedData: state => {
      return state.relatedData
    },
    loadingStatus: state => {
      return state.loadingStatus
    },
    pdfLoaded: state => {
      return state.pdfLoaded
    },
    visited: state => {
      return state.visited
    },
    clearRelated: state => {
      return state.relatedData
    }
  },
  mutations: {
    increment (state) {
      state.count++
    },
    SET_Term (state, payload) {
      // console.log('SETTING Term', payload)
      state.term = payload
    },
    CLEAR_Term (state) {
      // console.log('CLEARING Term', payload)
      state.term = ''
    },
    SET_SuggestedTerm (state, payload) {
      // console.log('SETTING Suggested Term', payload)
      state.suggestedTerm = payload
    },
    SET_monumentData (state, items) {
      // console.log('SETTING monumentData', items.data)
      if (items.data !== undefined) {
        state.monumentData.body = items.data
        state.monumentData.errorStatus = ''
      } if (items.error !== undefined) {
        state.monumentData.errorStatus = items.error
      }
    },
    SET_featuredData (state, items) {
      // console.log('SETTING FIGURE DATA', items.data)
      if (items.data !== undefined) {
        state.featuredData.body = items.data
        state.featuredData.errorStatus = ''
      } if (items.error !== undefined) {
        state.featuredData.errorStatus = items.error
      }
    },
    SET_curriculumData (state, items) {
      // console.log('SETTING FIGURE DATA', items.data)
      if (items.data !== undefined) {
        state.curriculumData.body = items.data
        state.curriculumData.errorStatus = ''
      } if (items.error !== undefined) {
        state.curriculumData.errorStatus = items.error
      }
    },
    SET_figureData (state, items) {
      // console.log('SETTING FIGURE DATA', items.data)
      if (items.data !== undefined) {
        state.figureData.body = items.data
        state.figureData.errorStatus = ''
      } if (items.error !== undefined) {
        state.figureData.errorStatus = items.error
      }
    },
    CLEAR_figureData (state) {
      state.figureData = { body: {}, header: {}, errorStatus: '' }
    },
    SET_relatedData (state, items) {
      // console.log('SETTING RELATED DATA', items.header)
      if (items.data !== undefined) {
        state.relatedData.header = items.header
        state.relatedData.body = items.data
        state.relatedData.errorStatus = ''
      } if (items.error !== undefined) {
        state.relatedData.errorStatus = items.error
      }
    },
    CLEAR_relatedData (state) {
      state.relatedData = { header: {}, body: {}, errorStatus: '' }
    },
    SET_titleData (state, items) {
      // console.log('setting title data', items)
      if (state.titleData.body.length > 0) {
        const joinedArray = state.titleData.body.concat(items.data)
        const uniqueArray = joinedArray.reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], [])
        state.titleData.body = uniqueArray.sort()
      } else {
        state.titleData.body = items.data
      }
    },
    SET_LoadingStatus (state, item) {
      state.loadingStatus.push(item)
    },
    SET_PDFLoaded (state, item) {
      if (item.status !== undefined) {
        state.pdfLoaded.status = item.status
      } if (item.page !== undefined) {
        state.pdfLoaded.page = item.page
      } if (item.pageHeight !== undefined) {
        state.pdfLoaded.pageHeight = item.pageHeight
      } if (item.errorStatus !== undefined) {
        state.pdfLoaded.errorStatus = item.errorStatus
      }
    },
    REWRITE_LoadingStatus (state, item) {
      const newarr = state.loadingStatus.filter(function (a) { return a !== item })
      state.loadingStatus = newarr
    },
    SET_Visited (state, items) {
      if (state.visited.indexOf(items.id) < 0) {
        state.visited.push(items.id)
      }
    }
  },
  actions: {
    increment ({ commit }) {
      commit('increment')
    },
    setTerm ({ commit }, payload) {
      if (payload.term) {
        commit('SET_Term', payload.term)
      }
    },
    clearTerm ({ commit }) {
      commit('CLEAR_Term')
    },
    setSuggestedTerm ({ commit }, payload) {
      commit('SET_SuggestedTerm', payload)
    },
    setVisited ({ commit }, payload) {
      commit('SET_Visited', payload)
    },
    setErrorStatus ({ commit }, payload) {
      commit('SET_ErrorStatus', payload)
    },
    clearRelatedData ({ commit }) {
      commit('CLEAR_relatedData')
    },
    apiRequest ({ commit }, payload) {
      axios.get(payload.path + payload.param)
        .then(response => (this.query = response.data)).then(querydata => {
          // console.log(payload, querydata)
          const dataResponse = querydata.body ? querydata.body : querydata
          if (payload.name === 'related') {
            commit(payload.mutation, { data: querydata.body, header: querydata.header })
            commit('SET_LoadingStatus', payload.name.toUpperCase())
          } else {
            commit(payload.mutation, { data: dataResponse })
            commit('SET_LoadingStatus', payload.name.toUpperCase())
          }
        })
        .catch(err => {
          if (err.response) {
            console.log('error response', payload, err.response)
            commit(payload.mutation, { error: err.response.status })
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            console.log('error request', err.request)
            // client never received a response, or request never left
          } else {
            console.log('err', err)
          }
        })
    },
    loadmonumentData ({ commit }, payload) {
      if (payload.type === 'id') {
        commit('REWRITE_LoadingStatus', payload.type.toUpperCase())
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_ID,
          mutation: 'SET_monumentData',
          param: payload.param,
          name: payload.type
        })
      } if (payload.type === 'term') {
        commit('REWRITE_LoadingStatus', payload.type.toUpperCase())
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_TERM,
          mutation: 'SET_monumentData',
          param: payload.param,
          name: payload.type
        })
      } if (payload.type === 'featured') {
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_FEATURED,
          mutation: 'SET_featuredData',
          param: payload.param,
          name: payload.type
        })
      } if (payload.type === 'predictive') {
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_PREDICTIVE,
          mutation: 'SET_titleData',
          param: payload.param,
          name: payload.type
        })
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_KEYWORDS,
          mutation: 'SET_titleData',
          param: payload.param,
          name: payload.type
        })
      } if (payload.type === 'curriculum') {
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_CURRICULUM,
          mutation: 'SET_curriculumData',
          param: payload.param,
          name: payload.type
        })
      } if (payload.type === 'figure') {
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_FIGURE,
          mutation: 'SET_figureData',
          param: payload.param,
          name: payload.type
        })
      } if (payload.type === 'profile') {
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_ID,
          mutation: 'SET_figureData',
          param: payload.param,
          name: payload.type
        })
      }
      if (payload.type === 'related') {
        store.dispatch({
          type: 'apiRequest',
          path: process.env.VUE_APP_API_RELATED,
          mutation: 'SET_relatedData',
          param: payload.param,
          name: payload.type
        })
      }
    },
    clearFigureData ({ commit }) {
      commit('CLEAR_figureData')
    },
    setPDFLoaded ({ commit }, payload) {
      commit('SET_PDFLoaded', payload)
    }
  }
})

export default store
