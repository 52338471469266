import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true
    },
    {
      path: '/search',
      name: 'SearchResults',
      props: true,
      component: () => import(/* webpackChunkName: "SearchResults" */ './views/SearchResults.vue')
    },
    {
      path: '/biography/:name/:id',
      name: 'BioPage',
      props: true,
      component: () => import(/* webpackChunkName: "BioPage" */ './views/BioPage.vue')
    },
    {
      path: '/figure/:id',
      name: 'FigurePage',
      props: true,
      component: () => import(/* webpackChunkName: "FigurePage" */ './views/FigurePage.vue')
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      props: true,
      component: () => import(/* webpackChunkName: "PrivacyPolicy" */ './views/LegalPage.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
