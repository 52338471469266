<template>
  <footer>
    <section class="flex-container">
      <div class="column">
        <h3>Follow</h3>
        <ul class="text-links">
          <li><a target="_blank" href="https://www.facebook.com/Movers-Shakers-NYC-110269582969467/">Facebook</a></li>
          <li><a target="_blank" href="https://twitter.com/mvrsandshakers?lang=en">Twitter</a></li>
          <li><a target="_blank" href="https://www.instagram.com/moversandshakersnyc/?hl=en">Instagram</a></li>
        </ul>
        <h3>Supporters</h3>
        <ul class="text-links">
          <li><a target="_blank" href="https://www.netflix.com/">Netflix</a></li>
          <li><a target="_blank" href="https://www.newinc.org/">New Inc</a></li>
          <li><a target="_blank" href="https://www.complex.com/">Complex</a></li>
          <li><a target="_blank" href="https://www.opensocietyfoundations.org/">Open Society Foundations</a></li>
          <li><a target="_blank" href="https://www.verizon.com/">Verizon</a></li>
          <li><a target="_blank" href="https://www.mothandflamevr.com/">Moth and Flame</a></li>
          <li><a target="_blank" href="https://www.google.com/">Google</a></li>
          <li><a target="_blank" href="https://www.ffwd.org/">Fast Forward</a></li>
          <li><a target="_blank" href="https://www.visible.com/connect">Visible Connect</a></li>
        </ul>
      </div>
      <div class="column">
        <h3>Monuments Project</h3>
        <p>We recognize that our country’s monuments, public spaces, school curricula and cultural institutions have systematically and intentionally excluded BIPOC history.  We started The Monuments Project as a way to create and insert hundreds of digital monuments into public spaces with or without permission.  We soon realized that we can shift the mindset of a generation by targeting the youth and placing our content in schools throughout the country.  We are merging culturally responsive pedagogy and primary source documents with our augmented reality monuments.</p>
        <ul class="image-links">
          <li><a target="_blank" href="https://apps.apple.com/us/app/kinfolk-ar/id1510605922"><img alt="App Play Store Button" height="54" src="@/assets/img/app-store-badge.png"></a></li>
          <li><a target="_blank" href="https://play.google.com/store/apps/details?id=ar.shakers.monuments"><img alt="Google Play Store Button" height="54" src="@/assets/img/google-play-badge.png"></a></li>
        </ul>
      </div>
      <div class="column">
        <ul class="image-links">
          <li><a target="_blank" href="https://www.moversandshakersnyc.com/"><img alt="Movers and Shakers NYC Logo" src="@/assets/img/movers-shakers-square.png"></a></li>
          <li><a target="_blank" href="http://www.langstonleague.com/"><img alt="Langston League Logo" src="@/assets/img/langston-league.png"></a></li>
          <li><a target="_blank" href="https://www.pariahinteractive.com/"><img alt="Pariah Interactive Logo" src="@/assets/img/Pariah_logo_black_256.png"></a></li>
        </ul>
      </div>
    </section>
    <div class="legal-links">
      <ul>
        <li>
          <router-link :to="{ path: '/privacy-policy' }">
            Privacy Policy
          </router-link>
        </li>
        <li><span>© {{new Date().getFullYear()}} Movers & Shakers</span></li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}

</script>

<style scoped lang="scss">
  #app.FigurePage,
  #app.BioPage,
  #app.TermsOfService,
  #app.PrivacyPolicy,
  #app.CookiePolicy {
    footer {
      margin-top: 0px;
    }
  }
  footer {
    width: 100%;
    background: $yellow;
    padding: $margin;
    margin-top: 40px;

    .flex-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      ul {
        padding-left: 0px;
        list-style-type: none;
        a {
          text-decoration: none;
          color: $darkplum;
        }
      }

      .column {
        padding-right: 3em;
        min-width: 30%;

        * {
          text-align: left;
          // font-size: 24px;
        }

        h3 {
          text-transform: uppercase;
        }

        ul.text-links {
          li {
            margin-bottom: 0.25em;
          }
        }
        ul.image-links {
          display: flex;
          flex-direction: row;
          li {
            display: inline;
            padding-right: 5px;
          }
        }
      }
      .column:nth-of-type(2){
        flex: 1 1 auto;
      }
      .column:nth-of-type(3){
        padding-right: 0;
        display: flex;
        align-items: center;
          ul.image-links {
            height: auto;
            align-items: center;
            flex-flow: wrap;
            justify-content: center;
            li {
              padding: 10px;
              a img {
                height: 120px;
              }
            }
          }
      }
    }
    .legal-links {
      width: 100%;
      margin-top: 1em;
      ul {
        text-align: left;
        margin: 0px;
        padding: 0px;
        li {
          display: inline;
          padding-right: 2em;
          * {
            font-size: 20px;
            color: #A3A3A3;
            text-decoration: none;
          }
          a:hover {
            color: $siena;
          }
        }
      }
    }
    @media (max-width: 768px) {
      padding: calc(#{$margin} / 2);
      .flex-container {
        display: block;
        .column {
          margin-top: 40px;
        }
        .column {
          padding-right: 0;
        }
        .column:nth-of-type(2){
          ul.image-links {
            display: block;
          }
        }
      }
      .legal-links ul li {
        display: block;
        width: 100%;
        padding: 0.25em 0;
      }
      .column:nth-of-type(3){
        ul.image-links {
          li a img {
            height: 80px!important;
          }
        }
      }
    }
  }
</style>
