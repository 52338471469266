import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import { mapState } from 'vuex'
import VueLazyload from 'vue-lazyload'
import VueGtag from 'vue-gtag'

const loadingimage = require('./assets/img/thumbnail-placeholder.png')
const errorimage = require('./assets/img/thumbnail-placeholder.png')

Vue.use(VueLazyload, {
  observer: true,
  preLoad: 1.3,
  loading: loadingimage,
  error: errorimage,
  attempt: 1
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ID }
}, router)

// REMOVE BEFORE DEPLOY TO PRODUCTION
// Vue.config.devtools = true
// Vue.config.productionTip = false

Vue.filter('formatDate', function (value, circa) {
  if (parseInt(circa) === 1) return value

  return moment(new Date(value)).format('MMM D, YYYY')
})

Vue.filter('mediaType', function (value) {
  if (!value) return ''
  if (value === '3d') return '3D Model'
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('monumentType', function (value) {
  if (!value) return ''
  if (value === 'figure') return 'Historical Figure'
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('sourceRank', function (value, text) {
  if (!value) return ''
  if (value === '1' || value === 1) return 'Primary Source'
  if (value === '2' || value === 2) return 'Secondary Source'
})

new Vue({
  router,
  store,
  computed: mapState([
    'term',
    'suggestedTerm',
    'monumentData',
    'featuredData',
    // 'curriculumData',
    'figureData',
    'relatedData',
    'titleData',
    'visited',
    'loadingStatus',
    'pdfLoaded'
  ]),
  render: h => h(App)
}).$mount('#app')
