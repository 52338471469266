<template>
  <div id="errorWrapper" class="error-message">
    <h1>This search did not return any results</h1>
  </div>
</template>

<script>

export default {
  name: 'NotFound'
}
</script>

<style scoped lang="scss">
  #errorWrapper.error-message {
    h1 {
      font-family: "Circular Std", $font-fallback;
      font-weight: normal;
    }
  }
</style>
