<template>
  <header v-bind:class="{ 'hidden-search': !formState }">
    <div class="logoRow">
      <router-link :to="{ name: 'home' }">
        <img
          alt="Kinfolk logo"
          src="@/assets/img/Kinfolk-Logo-Vector.svg"
          >
      </router-link>
      <button
        class="searchToggle"
        v-if="this.$route.name === 'FigurePage' || this.$route.name === 'BioPage'"
        v-show="!formState"
        @click="formState = true"
      >
        <i class="material-icons">search</i>
      </button>
    </div>
    <section
      class="search-modules"
    >
    <form
      id="searchForm"
      class="searchForm"
      v-if="formState"
      @submit="searchForTerm"
      @mouseup="showFeatured"
      v-click-outside="hideFeaturedtest"
    >
      <autocomplete
        :key="componentKey"
        ref="searchInput"
        :search="search"
        :debounce-time="300"
        placeholder="Search for a historical figure"
        aria-label="Search for a historical figure"
        :default-value="this.$route.query.term"
        @submit="onSubmit"
      >
      <template
        class="predictiveSuggest"
        #result="{ result, props }"
        >
        <li v-bind="props">
            <div class="result-wrapper">
              <h2>{{ result }}</h2>
              <i class="material-icons">search</i>
            </div>
        </li>
      </template>
    </autocomplete>
    <button class="searchButton" type="submit" form="searchForm">
      <img alt="Kinfolk Circle" src="@/assets/img/Kinfolk-Circle-Vector.svg">
    </button>
    <div class="error-message" v-if="emptySearch">
      <span>Please add a search query</span>
    </div>
     <div class="error-message" v-if="repeatSearch">
      <span>Please add a new search query</span>
    </div>
    <ul
      class="featured-search-container"
      v-if="showFeaturedElm"
      >
      <li class="featured-kwd"
        v-for="keyword in featuredData.body"
        v-bind:key="keyword.id"
        >
        <router-link
          class="result-wrapper"
          :to="{ name: 'SearchResults', query: { term: keyword.name }}"
        >
          <h2>{{ keyword.name }}</h2>
          <i class="material-icons">search</i>
        </router-link>
      </li>
    </ul>
    <NotFound
      :route="this.$route.name"
      v-if="monumentData.errorStatus === 404"
    />
    <SuggestTerm
      :key="'suggest-'+ componentKey"
      :route="this.$route.name"
      v-if="monumentData.errorStatus === 404 && titleData && this.$route.query.term"
    />
    </form>
  </section>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import SuggestTerm from '@/components/SuggestTerm'
import NotFound from '@/components/NotFound.vue'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'SearchBar',
  props: {
    showFullForm: Boolean
  },
  components: {
    Autocomplete,
    SuggestTerm,
    NotFound
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      formState: true,
      emptySearch: false,
      repeatSearch: false,
      showFeaturedElm: false,
      showFeaturedState: 0,
      componentKey: 0
    }
  },
  computed: mapGetters([
    'featuredData',
    'monumentData',
    'titleData',
    'term'
  ]),
  methods: {
    searchForTerm: function (e) {
      e.preventDefault()
      this.hideFeatured()
      const inputValue = this.$refs.searchInput.value
      if (inputValue === this.term) {
        this.repeatSearch = true
        this.emptySearch = false
      } if (inputValue === '') {
        this.emptySearch = true
        this.repeatSearch = false
      } if (inputValue !== '' && inputValue !== this.term) {
        this.onSubmit()
      }
    },
    forceRerender () {
      this.componentKey += 1
    },
    showFeatured: function () {
      this.showFeaturedElm = true
      this.emptySearch = false
      this.repeatSearch = false
    },
    hideFeatured: function () {
      this.showFeaturedElm = false
    },
    hideFeaturedtest: function () {
      this.showFeaturedElm = false
    },
    search: function (input) {
      if (input.length < 1) { return [] }
      this.hideFeatured()
      return this.titleData.body.filter(title => {
        return title.toLowerCase()
          .startsWith(input.toLowerCase())
      })
    },
    getResultValue (result) {
      return result
    },
    onSubmit (query) {
      const q = query || this.$refs.searchInput.value
      if (q !== '' && q !== this.term) {
        this.$router.push({ name: 'SearchResults', query: { term: q } })
      }
    },
    setFormState () {
      if (
        this.$route.name === 'BioPage' ||
        this.$route.name === 'PrivacyPolicy'
      ) {
        this.formState = false
      } if (
        this.$route.name === 'FigurePage' &&
        this.monumentData.errorStatus !== 404
      ) {
        this.formState = false
      } if (
        this.$route.name === 'home' ||
        this.$route.name === 'SearchResults'
      ) {
        this.formState = true
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.setFormState()
      this.hideFeatured()
      // when route is changed, re-render search bar to populate default value with route query
      this.forceRerender()
      this.emptySearch = false
      this.repeatSearch = false
    }
    // monumentData (newStatus) {
    //   console.log('status log', newStatus)
    //   if (newStatus.errorStatus === 404 && this.$route.name === 'FigurePage') {
    //     this.formState = true
    //   }
    // }
  },
  mounted: function () {
    // console.log(this.$route.name)
    this.setFormState()
    this.hideFeatured()
    if (this.featuredData.body.length === undefined) {
      this.$store.dispatch('loadmonumentData', { type: 'featured', param: '' })
    } if (this.titleData.body.length === undefined) {
      this.$store.dispatch('loadmonumentData', { type: 'predictive', param: '' })
    } if (this.$route.fullPath === '/search?term=') {
      // redirect to homepage if search is empty
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss">
  #app.BioPage,
  #app.FigurePage {
    header .logoRow img {
      max-width: 295px;
      width: unset;
    }
  }
  header {
    padding: 40px;
    text-align: left;
    @media (max-width: 768px) {
      padding: 1em;
      .logoRow img {
        width: 200px!important;
      }
    }
    .logoRow {
      text-align: center;
      img {
        width: 100%;
        max-width: 438px;
        margin: 0 auto;
        vertical-align: bottom;
        transition: all $transition-defaults;
      }
      .searchToggle {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0px;
        margin: $margin;
        border-radius: 50%;
        outline: none;
        border: none;
        background: $robinblue;
        cursor: pointer;
        @media (max-width: 768px) {
          width: 35px;
          height: 35px;
          margin: 0 calc(#{$margin} / 2);
          padding: 0;
        }
        i {
          color: $plum;
        }
      }
    }

    &.hidden-search {
      .logoRow {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: calc(100% - 30px);
      }
    }
  }

  .searchForm {
    position: relative;
    width: 100%;
    max-width: 863px;
    margin: $margin auto 0 auto;
    @media (max-width: 768px) {
      margin: 1em auto 0 auto;
    }
    &:focus-within {
      .featured-search-container {
        display: block;
      }
    }
    .searchButton {
      position: absolute;
      top: 0px;
      right: 15px;
      width: 35px;
      height: 64px;
      line-height: 45px;
      border-radius: 64px;
      border: 0;
      padding: 0;
      background: transparent;
      display: flex;
      @media (max-width: 768px) {
        height: 48px;
      }
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
      img {
        width: 100%;
        margin: auto;
        cursor: pointer;
      }
    }
    input[type='text'],
    input.autocomplete-input {
      background: $robinblue;
      width: 100%;
      height: 48px;
      border-radius: 64px;
      text-align: center;
      font-size: 24px;
      padding: 0 1em;
      border: 0px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
      &::placeholder {
        color: $plum;
      }
      &:active,
      &:focus {
        text-align: left;
        border: none;
        outline: none;
        color: $plum;
        &::placeholder {
          color: transparent;
        }
      }
    }

    img.circle-vector {
      position: absolute;
      left: 0;
      width: 64px;
      height: 48px;
      line-height: 54px;
      border-radius: 64px;
      height: 54px;
      line-height: unset;
    }
    ul.featured-search-container {
      /*display: none;*/
    }
    /* ul.autocomplete-result-list {
      margin-top: 1em!important;
    } */
    ul.featured-search-container,
    ul.autocomplete-result-list {
      position: absolute!important;
      width: 100%;
      z-index: 10!important;
      background: $robinblue;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      margin-top: 10px;
      border-radius: 20px;
      list-style-type: none;
      padding: 3px 10px;
      max-height: 320px;
      overflow: scroll;
      @media (max-width: 768px) {
        padding: 5px;
      }
      li {
        width: 100%;
        padding: 5px 10px;
        margin: 10px 0;
        border-radius: 48px;
        * { color:$plum; }
        @media (max-width: 768px) {
          margin: 0;
          padding: 0px 8px;
           * { font-size: 16px;}
          }
        &:hover {
          background: $plum;
          color: white;
          * { color: white; }
        }
      }
      .result-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        h2 {
          margin: 10px;
          font-family: 'Circular Std', $font-fallback;
          font-weight: normal;
        }
      }
    }
  }

  .error-message {
    margin: $margin;
    * {
      font-size: 24px;
      font-weight: normal;
      color: $robinblue;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  @media (min-width: 768px) {
    .searchForm {
      input[type='text'],
      input.autocomplete-input {
        height: 64px;
      }

      .searchButton {
        height: 64px;
        width: 45px;
        line-height: unset;
      }
    }
  }
</style>
