<template>
  <div id="Home">
    <FigureList/>
  </div>
</template>

<script>
import FigureList from '@/components/FigureList.vue'

export default {
  name: 'home',
  components: {
    FigureList
  }
}
</script>

<style lang="scss">
  #Home section {
    padding-top: calc(40px - 1em);
    > h1 {
      color: $yellow;
    }
    @media (max-width: 768px) {
      padding: 0 1em;
      > h1 {
        font-size: 24px;
      }
    }
  }
</style>
