<template>
<div id="loader-spacer">
  <div id="loader-container">
    <div id="loader"></div>
  </div>
</div>
</template>

<script>

export default {
  name: 'Loader'
}
</script>

<style scoped lang="scss">
#loader-container {
  width: 100%;
  height: calc(100vh - 280px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  #loader {
      display: inline-block;
      width: 186px;
      height: 186px;
      background-image: url(../assets/img/loader-small-clean.png);
      background-size: 1302px 186px;
      animation: sprite-cycle 0.65s steps(7) infinite;
  }
}
@keyframes sprite-cycle {
  0% {
    background-position-x: 0px;
  }

  100% {
    background-position-x: 1302px;
  }
}
</style>
