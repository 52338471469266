<template>
  <div id="app" :class="$route.name">
    <SearchBar/>
    <router-view :key="$route.path"/>
    <Footer/>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    SearchBar,
    Footer
  },
  watch: {
    '$route' (to, from) {
      this.$store.dispatch('clearFigureData')
      if (this.$route.name === 'SearchResults') {
        this.$store.dispatch('setTerm', this.$route.query)
      } if (this.$route.name === 'home') {
        this.$store.dispatch('clearTerm')
      } if (this.$route.name !== 'FigurePage') {
        this.$store.dispatch('setPDFLoaded', { status: false, errorStatus: '', pageHeight: '' })
      } if (this.$route.name !== 'BioPage') {
        this.$store.dispatch('clearRelatedData')
      }
    }
  }
}
</script>

<style lang="scss">

@import './assets/css/normalize.css';

* {
  box-sizing: border-box;
}

#app {
  font-family: 'Circular Std', $font-fallback;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background: url('./assets/img/Kinfolk-Painted-Bg-Small.png');
}
</style>
