<template>
  <section id="FigureList">
    <h1>Biographies</h1>
    <h4>Explore hidden figures in history.</h4>
    <Loader v-if="loadingStatus.indexOf('FIGURE') === -1"/>
    <ul class="cards-wrapper"  v-if="loadingStatus.indexOf('FIGURE') >= 0" >
      <CardBasic
        v-for="(item, index) in figureData.body"
        v-bind:key="item.id"
        v-bind:item="item"
        v-bind:index="index"
      />
    </ul>
  </section>
</template>

<script>
import Loader from '@/components/Loader.vue'
import CardBasic from '@/components/CardBasic.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FigureList',
  components: {
    Loader,
    CardBasic
  },
  computed: mapGetters([
    'loadingStatus',
    'figureData'
  ]),
  methods: {
    getSearchResults: function () {
      this.$store.dispatch('loadmonumentData', { type: 'figure', param: '' })
    }
  },
  mounted: function () {
    this.getSearchResults()
  }
}
</script>

<style lang="scss">
  #FigureList {
    width: 100%;
    margin: 0 auto;
    > h1 {
      margin: 0.3em 0;
    }
    h4 {
      font-family: "Circular Std", Avenir, Helvetica, Arial, sans-serif;
      font-weight: normal;
      font-size: 1.25em;
      margin: 0.5em auto;
      max-width: 600px;
      color: $yellow;
    }
    .card.monument {
      @media (max-width: 768px) {
        margin: 0 calc(#{$margin} / 2) calc(#{$margin} / 2) calc(#{$margin} / 2);
      }
      &:hover {
        transform: scale(1.025) translateZ(0);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }

// Card styles in src/assets/scss/cards.scss
</style>
