<template>
  <div id="did-you-mean" class="error-message">
   <router-link  class="result-wrapper" :to="{ name: 'SearchResults', query: { term: suggestedTerm }}">
    <i class="material-icons">subdirectory_arrow_right</i> Did you mean <strong>{{suggestedTerm}}</strong> ?
    </router-link>
  </div>
</template>

<script>
import { closest } from 'fastest-levenshtein'
import { mapGetters } from 'vuex'

export default {
  name: 'SuggestTerm',
  computed: mapGetters([
    'suggestedTerm',
    'titleData'
  ]),
  methods: {
    returnLevenshtein () {
      const levenshtein = closest(this.$route.query.term, this.titleData.body)
      this.$store.dispatch('setSuggestedTerm', levenshtein)
    }
  },
  mounted: function () {
    this.returnLevenshtein()
  }
}
</script>

<style scoped lang="scss">

</style>
