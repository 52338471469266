<template>
  <li
   :id="item.id"
   :data-type="item.monument_type"
   v-bind:class="['featured-kwd card', { visited: visited.indexOf(item.id) >= 0 }]"
   v-bind:key="item.id">
    <router-link v-if="item.monument_type!=='figure'" :to="{ name: 'SearchResults', query: { term: item.name }}">
      <figure class="img-container">
        <img class="lazy-image" v-lazy="item.thumbnail_src"/>
      </figure>
      <figcaption>{{ item.name }}</figcaption>
    </router-link>
    <router-link v-if="item.monument_type==='figure'" :to="{ name: 'BioPage', params: { name: item.title, id: item.id }}">
      <figure class="img-container">
        <img class="lazy-image" v-lazy="item.thumbnail_src"/>
      </figure>
      <figcaption v-if="item.title">{{ item.title }}</figcaption>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Card',
  props: {
    item: Object
  },
  computed: mapGetters([
    'term',
    'visited'
  ])
}
</script>

<style lang="scss">

</style>
